<template>
  <!-- eslint-disable -->
  <div class="w-100">
    <b-overlay
      variant="white"
      :show="showLoading"
      spinner-variant="primary"
      blur="0"
      opacity=".75"
      rounded="sm"
    >
      <!-- <filters
        :filtros="fields"
        :is-add.sync="isAdd"
        ref="filters"
        @update:filtros="value => (fields = value)"
      ></filters> -->
      <filter-columns
        :filtros="fields"
        :is-add.sync="isAdd"
        ref="filters"
        @update:filtros="value => (fields = value)"
      />
      <b-card
        ref="filterContent"
        no-body
        class="sticky"
      >
        <b-card-body>
          <b-row>
            <b-col
              md="12"
              lg="12"
              class="d-flex flex-column flex-lg-row"
            >
              <div class="w-100">
                
                <b-form-group
                  label="Proyecto"
                  label-for="project"
                  class="mr-2"
                >
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="proyectos"
                    label="code"
                    input-id="project"
                    :reduce="proyectos => proyectos.id"
                    placeholder="Proyecto"
                    v-model="project_id"
                    @input="selectProject(project_id)"
                    class="select-obra"
                    disabled
                  >
                    <template v-slot:selected-option="option"> {{ option.code }} - {{ option.description }} </template>
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      {{ option.code }} - {{ option.description }}
                    </template>
                  </v-select>
                </b-form-group>
              </div>
              <div class="w-100">
                <b-form-group
                  label="Descripción"
                  label-for="description"
                  class="mr-2"
                >
                  <b-form-input
                    type="text"
                    label="name"
                    id="description"
                    placeholder="Descripción"
                    v-model="description"
                    @input="filter()"
                    class="select-obra"
                    autocomplete="off"
                  >
                  </b-form-input>
                </b-form-group>
              </div>
              <div class="w-100">
                <b-form-group
                  label="Dueño"
                  label-for="dueno"
                  class="mr-2"
                >
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="duenos"
                    label="name"
                    input-id="dueno"
                    :reduce="duenos => duenos.id"
                    placeholder="Dueño"
                    v-model="dueno_id"
                    @input="filter()"
                    class="select-obra"
                  >
                    <template v-slot:selected-option="option">
                      {{ option.name }} - {{ option.area ? option.area.description : '' }}
                    </template>
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      {{ option.name }} - {{ option.area ? option.area.description : '' }}
                    </template>
                  </v-select>
                </b-form-group>
              </div>
              <div class="w-100">
                <b-form-group
                  label="Usuarios"
                  label-for="usuario"
                  class="mr-2"
                >
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="usuarios"
                    label="name"
                    input-id="usuario"
                    :reduce="usuarios => usuarios.id"
                    placeholder="Usuario"
                    v-model="user_id"
                    @input="filter()"
                  >
                    <template v-slot:selected-option="option"> {{ option.name }} - {{ option.dni + '' }} </template>
                    <template
                      slot="option"
                      slot-scope="option"
                    >
                      {{ option.name }} - {{ option.dni + '' }}
                    </template>
                  </v-select>
                </b-form-group>
              </div>
              <div class="w-50">
                <b-form-group
                  label="Condicion"
                  label-for="status"
                  class="mr-2"
                >
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="estadosFilter"
                    label="text"
                    input-id="status"
                    :reduce="estadosFilter => estadosFilter.value"
                    placeholder="Condicion"
                    v-model="statusFilter"
                    @input="filter()"
                    class="select-obra"
                  >
                  </v-select>
                </b-form-group>
              </div>
              <div class="w-50">
                <b-form-group
                  label="Estado"
                  label-for="status_similar"
                  class="mr-2"
                >
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="estadosNuevos"
                    label="text"
                    input-id="status_similar"
                    :reduce="estadosNuevos => estadosNuevos.value"
                    placeholder="Condicion"
                    v-model="status_similar"
                    @input="filter()"
                    class="select-obra"
                  >
                  </v-select>
                </b-form-group>
              </div>
            </b-col>
            <b-col
              md="12"
              lg="12"
              class="d-flex"
            >
              <div
                class="d-flex flex-column flex-lg-row align-items-center h-100 w-100 justify-content-center mb-1 mb-lg-0 mt-02"
              >
                <div class="d-flex">
                  <b-button
                    v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                    variant="secondary"
                    class="mr-1"
                    @click.prevent="clean"
                  >
                    <span class="align-middle">Ver todos</span>
                  </b-button>
                  <b-button
                    v-if="user_role != 'user'"
                    class=""
                    variant="primary"
                    @click="addRestric(project_id)"
                  >
                    <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon" /> Agregar </span>
                  </b-button>
                  <b-button
                    @click="showFilters"
                    variant="primary"
                    class="text-left ml-1 w-auto"
                    block
                    v-b-toggle="'accordion1'"
                  >
                    <feather-icon
                      v-if="show"
                      icon="PlusCircleIcon"
                      size="15"
                    />
                    <feather-icon
                      v-else
                      icon="MinusCircleIcon"
                      size="15"
                    />
                    Columnas
                  </b-button>
                </div>
                <div
                  v-if="user_role != 'user'"
                  class="d-flex mt-1 mt-lg-0"
                >
                  <b-button
                    class="mx-0 mx-lg-2"
                    variant="warning"
                    @click="importData"
                  >
                    <span class="text-nowrap"> <feather-icon icon="DownloadIcon" /> Importar </span>
                  </b-button>
                  <b-button
                    variant="success"
                    class="ml-1"
                    @click="exportExcel(project_id)"
                  >
                    <span class="text-nowrap"> <feather-icon icon="UploadIcon" /> Exportar </span>
                  </b-button>
                </div>
              </div>
            </b-col>
          </b-row>
          <!-- <b-row v-if="selectedRecords.arrayId.length > 0">
            <b-col sm="12" md="3">
              <div class="w-100 mb-1 mb-lg-0">
                <b-form-group label="Estado" label-for="status" class="mr-2">
                  <v-select
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="user_role == 'user' ? estados2 : estados"
                    label="text"
                    input-id="status"
                    :reduce="(estados) => estados.value"
                    placeholder="Estado"
                    v-model="status"
                  />
                </b-form-group>
              </div>
            </b-col>
            <b-col sm="12" md="4">
              <div class="w-100 mb-1 mb-lg-0 d-flex align-items-center h-100">
                <b-button class="mr-2 mb-1 mb-md-0" variant="primary" @click="changeStatus()">
                  <span class="text-nowrap"> <feather-icon icon="PlusCircleIcon" /> Cambiar </span>
                </b-button>
              </div>
            </b-col>
          </b-row> -->
        </b-card-body>
      </b-card>
      <!-- @row-selected="onRowSelectedOrder" / selectable -->
      <b-card
        no-body
        ref="tableCard"
      >
        <div class="table-overflow">
          <b-table
            class="position-relative"
            empty-text="No existen"
            :fields="visibleFields"
            :hover="true"
            id="__BVID__185"
            :items="records"
            no-border-collapse
            ref="selectableTable"
            :responsive="false"
            show-empty
            style="font-size: 14px !important"
            @row-selected="onRowSelectedOrder"
            @sort-changed="sortChanged"
          >
            <!-- Column: Actions -->
            <template #head(row)="">
              <div class="d-flex gap-2">
                <b-form-checkbox
                  id="checkall"
                  :checked="checkAll"
                  @change="selectAll((checkAll = !checkAll))"
                />
              </div>
            </template>

            <!-- thead templates -->
            <template #head(description)="data">
              <div class="description-class">
                <span>{{ data.label }}</span>
              </div>
            </template>

            <template #head(owner.name)="data">
              <div class="owner-class">
                <span>{{ data.label }}</span>
              </div>
            </template>
            

            <template #head(date_identified)="data">
              <div class="date-class">
                <span>{{ data.label }}</span>
              </div>
            </template>

            <template #head(date_required)="data">
              <div class="date-class">
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(date_commited)="data">
              <div>
                <span>{{ data.label }}</span>
              </div>
            </template>
            <template #head(date_released)="data">
              <div class="date-liberado-class">
                <span>{{ data.label }}</span>
              </div>
            </template>
            
            <!-- tbody tempaltes -->
            <template #cell(row)="data">
              <div style="width: 0px !important">
                <b-form-checkbox
                disabled
                :checked="selectedRecords.arrayId.includes(data.item)"
                />
              </div>
            </template>
            

            <template
              v-if="user_role != 'user'"
              #cell(actions)="data"
            >
              <div class="column-actions">
                <b-button
                  size="sm"
                  class=""
                  @click.prevent="showDates(data.item)"
                  v-b-tooltip.noninteractive.hover.left="'Historial de Fechas'"
                  variant="flat-secondary"
                >
                  <feather-icon icon="CalendarIcon" />
                </b-button>
                <b-button
                  size="sm"
                  class=""
                  @click.prevent="showTimeLine(data.item)"
                  v-b-tooltip.noninteractive.hover.left="'Comentarios'"
                  variant="flat-info"
                >
                  <feather-icon icon="MessageCircleIcon" />
                </b-button>
                <b-button
                  size="sm"
                  class=""
                  @click.prevent="view(data.item)"
                  v-b-tooltip.noninteractive.hover.left="'Vista'"
                  variant="flat-primary"
                >
                  <feather-icon icon="EyeIcon" />
                </b-button>
                <b-button
                  size="sm"
                  class=""
                  @click.prevent="edit(data.item)"
                  v-b-tooltip.noninteractive.hover.left="'Editar'"
                  variant="flat-success"
                  v-if="data.item.status_similar != 'Cerrado'"
                >
                  <feather-icon icon="Edit2Icon" />
                </b-button>
                <b-button
                  size="sm"
                  class=""
                  v-b-tooltip.noninteractive.hover.left="'Archivos'"
                  @click="openModal(data.item.id)"
                  variant="flat-success"
                >
                  <feather-icon icon="FileIcon" />
                </b-button>
                <b-button
                  size="sm"
                  class=""
                  v-b-tooltip.noninteractive.hover.left="'Eliminar'"
                  @click="deleteAction(data.item)"
                  variant="flat-danger"
                >
                  <feather-icon icon="Trash2Icon" />
                </b-button>
              </div>
            </template>

            <template #cell(description)="data">
              <div class="description-class">
                <span>{{ data.item.description }}</span>
              </div>
            </template>
            <template #cell(critic_route)="data">
              <div class="date-liberado-class">
                <span v-if="data.item.critic_route">Sí</span>
                <span v-else>No</span>
              </div>
            </template>
            <template #cell(category.code)="data">
              <div class="description-class">
                <span>{{ data.item.category.code }} - {{ data.item.category.description }}</span>
              </div>
            </template>
            <template #cell(location_1.code)="data">
              <div  class="description-class">
                <span>{{ data.item.location_1 ? data.item.location_1.description : ''  }}</span>
              </div>
            </template>
            <template #cell(location_2.code)="data">
              <div  class="description-class">
                <span>{{ data.item.location_2 ? data.item.location_2.description : ''  }}</span>
              </div>
            </template>
            <template #cell(location_3.code)="data">
              <div  class="description-class">
                <span>{{ data.item.location_3 ? data.item.location_3.description : ''  }}</span>
              </div>
            </template>
            <template #cell(location_4.code)="data">
              <div  class="description-class">
                <span>{{ data.item.location_4 ? data.item.location_4.description : ''  }}</span>
              </div>
            </template>
            <template #cell(owner.name)="data">
              <div class="owner-class">
                <span
                  v-if="data.item.userGone"
                  style="color: red; text-decoration: line-through"
                  >{{ data.item.owner ? data.item.owner.name : '' }}</span
                >
                <span v-else>{{ data.item.owner ? data.item.owner.name : '' }}</span>
              </div>
            </template>

            <template #cell(date_identified)="data">
              <div class="date-class">
                <span>{{ validDate(data.item.date_identified) }}</span>
              </div>
            </template>
            <template #cell(status_similar)="data">
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="data.item.status_similar === 'Abierto' ? 'warning' : 'success'"
                href="#"
                >{{ data.item.status_similar }}</b-button
              >
            </template>
            <template #cell(light)="data">
              <div style="display: flex; justify-content: center">
                <span
                  :style="{
                    background: data.item.light ? 'rgb(255, 0, 0)' : 'rgb(64, 155, 64)',
                    borderRadius: '50%',
                    width: '25px',
                    height: '25px',
                    display: 'inline-block',
                  }"
                >
                </span>
              </div>
            </template>
            <template #cell(date_required)="data">
              <div class="date-class">
                <span>{{ validDate(data.item.date_required) }}</span>
              </div>
            </template>
            <template #cell(reason_reject)="data">
              <div style="color: red">
                <span style="font-weight: bold">{{ data.item.reason_reject }}</span>
              </div>
            </template>
            <template #cell(date_commited)="data">
              <div class="date-class">
                <span>{{ validDate(data.item.date_commited) }}</span>
              </div>
            </template>
            <template #cell(date_released)="data">
              <div class="date-liberado-class">
                <span>{{ validDate(data.item.date_released) }}</span>
              </div>
            </template>
          </b-table>
        </div>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col sm="3">
              <b-form-group
                label-cols="4"
                label-cols-md="4"
                label-size="md"
                label="Entradas"
                label-for="input-md"
              >
                <b-form-select
                  v-model="showEntrie"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="entries"
                  @change="changeSizePage"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="3"
              class="mt-75"
              style="font-size: 12px"
            >
              <span> {{ totalElements }} Registros en total</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage"
                :total-rows="totalElements"
                :per-page="showEntrie"
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="cambioPagina"
                pills
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="15"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="15"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-card>
      <b-modal
        id="modal-comment"
        ref="modal-comment"
        centered
        title="Comentarios"
        ok-only
        hide-footer
        :size="addComent && timeLine.length > 0 ? 'lg' : ''"
        @hide="closeComment"
      >
        <b-card-text class="card_content pb-2">
          <b-row>
            <b-col :md="timeLine.length > 0 ? '12' : '4'">
              <span>{{ title }}</span>
            </b-col>
            <b-col
              md="12"
              :class="timeLine.length > 0 ? 'text-right' : 'text-center'"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                :variant="!addComent ? 'danger' : 'warning'"
                @click="addComent = !addComent"
              >
                <span v-if="!addComent">Agregar</span>
                <span v-else>Ocultar</span>
              </b-button>
            </b-col>
          </b-row>
          <hr v-if="timeLine.length > 0" />
          <div class="content_main">
            <b-row :class="{ content_form: timeLine.length == 0 }">
              <b-col
                :md="addComent && timeLine.length > 0 ? '6' : '12'"
                :class="{ content_timeline: timeLine.length > 0 }"
              >
                <app-timeline>
                  <app-timeline-item
                    v-for="(item, index) in timeLine"
                    :key="index"
                    variant="primary"
                  >
                    <div class="d-flex flex-sm-row flex-column flex-wrap justify-content-between mb-1 mb-sm-0">
                      <h6>
                        {{ item.user_id != null ? item.user.name : 'sistema de restricciones' }}
                      </h6>
                      <small class="text-muted">{{ moment(item.created_at).format('yyyy-MM-DD') }}</small>
                    </div>
                    <p>{{ item.description }}</p>
                    <!-- <p>{{ item.description }}</p> -->
                    <!-- <p v-if="item.file_path != ''">
                      <b-img
                        :src="require('@/assets/images/pdf.png')"
                        height="auto"
                        width="20"
                        class="mr-1"
                      />
                      <a :href="item.file_path" target="_blank"
                        ><span class="align-bottom">Descargar</span></a
                      >
                    </p> -->
                  </app-timeline-item>
                </app-timeline>
              </b-col>
              <b-col
                :md="timeLine.length > 0 ? '6' : '12'"
                v-if="addComent"
              >
                <validation-observer ref="addComentarios">
                  <b-form
                    :class="{ section_add: timeLine.length > 0 }"
                    @submit.prevent="addComment"
                  >
                    <b-row>
                      <b-col
                        cols="12"
                        md="12"
                      >
                        <b-form-group
                          label="Comentario"
                          label-for="comentario"
                        >
                          <validation-provider
                            #default="{ errors }"
                            rules="required"
                            name="comment"
                          >
                            <b-form-textarea
                              placeholder="Comentario"
                              id="comentario"
                              rows="3"
                              no-resize
                              v-model="comment"
                            />
                            <small
                              v-if="errors.length > 0"
                              class="text-danger"
                              >El campo es requerido</small
                            >
                          </validation-provider>
                        </b-form-group>
                      </b-col>
                    </b-row>
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      type="submit"
                      >Agregar</b-button
                    >
                  </b-form>
                </validation-observer>
              </b-col>
            </b-row>
          </div>
        </b-card-text>
      </b-modal>
      <b-modal
        id="modal-dates"
        ref="modal-dates"
        centered
        title="Historial de Cambio de Fechas"
        ok-only
        hide-footer
        size="lg"
      >
        <b-table
          class="position-relative"
          :hover="true"
          :items="dates"
          responsive
          :fields="fields3"
          show-empty
          empty-text="No existen"
          @sort-changed="sortChanged"
          no-border-collapse
          style="max-height: 305px !important; overflow-y: scroll"
        >
          <!-- Column: Actions -->

          <template #cell(created_at)="data">
            <span>{{ validDate(data.item.created_at) }}</span>
          </template>
          <template #cell(date_after)="data">
            <span>{{ validDate(data.item.date_after) }}</span>
          </template>
          <template #cell(date_before)="data">
            <span>{{ validDate(data.item.date_before) }}</span>
          </template>
        </b-table>
        <div class="mx-2 mb-2">
          <b-row>
            <b-col sm="3">
              <b-form-group
                label-cols="4"
                label-cols-md="4"
                label-size="md"
                label="Entradas"
                label-for="input-md"
              >
                <b-form-select
                  v-model="showEntrie1"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  label="title"
                  :options="entries1"
                  @change="changeSizePage1"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="3"
              class="mt-75"
              style="font-size: 12px"
            >
              <span> {{ totalElements1 }} Registros en total</span>
            </b-col>
            <!-- Pagination -->
            <b-col
              cols="12"
              sm="6"
              class="d-flex align-items-center justify-content-center justify-content-sm-end"
            >
              <b-pagination
                v-model="currentPage1"
                :total-rows="totalElements1"
                :per-page="showEntrie1"
                class="mb-0 mt-1 mt-sm-0"
                prev-class="prev-item"
                next-class="next-item"
                @change="cambioPagina1"
                pills
              >
                <template #prev-text>
                  <feather-icon
                    icon="ChevronLeftIcon"
                    size="15"
                  />
                </template>
                <template #next-text>
                  <feather-icon
                    icon="ChevronRightIcon"
                    size="15"
                  />
                </template>
              </b-pagination>
            </b-col>
          </b-row>
        </div>
      </b-modal>
      <b-modal
        size="xl"
        v-model="modalOpen"
        @close="closeModal"
        hide-footer
      >
        <template v-slot:default>
          <archivos
            :edit="true"
            :itemId="itemId"
            ref="archivos"
          />
        </template>
      </b-modal>
    </b-overlay>
  </div>
</template>

<script>
  /* eslint-disable */
  import FilterColumns from '@/components/FilterColumns.vue'
  import OwnerService from '@/services/OwnerService'
  import ProjectsService from '@/services/ProjectsService'
  import RestrictionsService from '@/services/RestrictionsService'
  import UserService from '@/services/UserService'
  import AppCollapse from '@core/components/app-collapse/AppCollapse.vue'
  import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue'
  import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
  import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
  import { BootstrapVue, BootstrapVueIcons, VBTooltip } from 'bootstrap-vue'
  import Vue from 'vue'
  import flatPickr from 'vue-flatpickr-component'
  import Ripple from 'vue-ripple-directive'
  import vSelect from 'vue-select'
  import filters from './filters.vue'
  import archivos from './steps/archivos.vue'

  import { required } from '@validations'
  import moment from 'moment'
  import { ValidationObserver, ValidationProvider } from 'vee-validate'
  const APIURL = process.env.APIURLFILE
  Vue.use(BootstrapVue)
  Vue.use(BootstrapVueIcons)
  export default {
    directives: {
      'b-tooltip': VBTooltip,
      Ripple,
    },
    data() {
      return {
        status: '',
        statusFilter: '',
        status_similar: 'Abierto',
        required,
        apiurl: APIURL,
        showLoading: false,
        show: true,
        timeLine: [],
        modalOpen: false,
        title: '',
        comment: '',
        addComent: false,
        showPDF: false,
        // fields: [
        //   { key: 'row', label: '', sortable: false, visible: false, thStyle: { width: '80px' } },
        //   { key: 'code', label: 'Id-Restricción', sortable: true, visible: false, thStyle: { width: '160px' } },
        //   { key: 'light', label: 'Semáforo', sortable: true, visible: true, thStyle: { width: '100px' } },
        //   { key: 'description', label: 'Descripción', sortable: true, visible: true, thStyle: { width: '500px' } },
        //   { key: 'category.code', label: 'Categoria', sortable: true, visible: true, thStyle: { width: '150px' } },
        //   {
        //     key: 'sub_category.code',
        //     label: 'Sub-Categoria',
        //     sortable: true,
        //     visible: false,
        //     thStyle: { width: '150px' },
        //   },
        //   { key: 'priority', label: 'Prioridad', sortable: true, visible: true, thStyle: { width: '150px' } },
        //   { key: 'status_similar', label: 'Estado', sortable: true, visible: true, thStyle: { width: '150px' } },
        //   { key: 'status', label: 'Condicion', sortable: true, visible: true, thStyle: { width: '140px' } },
        //   { key: 'user.name', label: 'Usuario Reg', sortable: true, visible: true, thStyle: { width: '160px' } },
        //   { key: 'reason_reject', label: 'Motivo Rechazo', sortable: true, visible: false, thStyle: { width: '150px' } },
        //   { key: 'owner.name', label: 'Dueño', sortable: true, visible: true, thStyle: { width: '150px' } },
        //   { key: 'co_owner.name', label: 'Co-Dueño', sortable: true, visible: false, thStyle: { width: '150px' } },
        //   { key: 'type', label: 'Tipo', sortable: true, visible: false, thStyle: { width: '100px' } },
        //   { key: 'detail', label: 'Detalle', sortable: true, visible: false, thStyle: { width: '125px' } },
        //   { key: 'dependency', label: 'Dependencia', sortable: false, visible: false, thStyle: { width: '155px' } },
        //   { key: 'leadtime', label: 'LeadTime', sortable: false, visible: false, thStyle: { width: '125px' } },
        //   {
        //     key: 'date_identified',
        //     label: 'Fecha. Identificada',
        //     sortable: true,
        //     visible: true,
        //     thStyle: { width: '155px' },
        //   },
        //   {
        //     key: 'speciality.description',
        //     label: 'Especialidad',
        //     sortable: true,
        //     visible: true,
        //     thStyle: { width: '155px' },
        //   },
        //   { key: 'date_required', label: 'Fecha. Requerida', sortable: true, visible: true, thStyle: { width: '155px' } },
        //   {
        //     key: 'date_commited',
        //     label: 'Fecha. Comprometida',
        //     sortable: true,
        //     visible: true,
        //     thStyle: { width: '175px' },
        //   },
        //   { key: 'date_released', label: 'Fecha. Liberada', sortable: true, visible: true, thStyle: { width: '155px' } },
        //   { key: 'project.code', label: 'Proyecto', sortable: true, visible: false, thStyle: { width: '130px' } },
        //   { key: 'activity.code', label: 'Actividad', sortable: true, visible: false, thStyle: { width: '130px' } },
        //   {
        //     key: 'location_1.code',
        //     label: 'Localizacion 1',
        //     sortable: true,
        //     visible: false,
        //     thStyle: { width: '150px' },
        //   },
        //   {
        //     key: 'location_2.code',
        //     label: 'Localizacion 2',
        //     sortable: true,
        //     visible: false,
        //     thStyle: { width: '150px' },
        //   },
        //   {
        //     key: 'location_3.code',
        //     label: 'Localizacion 3',
        //     sortable: true,
        //     visible: false,
        //     thStyle: { width: '150px' },
        //   },
        //   {
        //     key: 'location_4.code',
        //     label: 'Localizacion 4',
        //     sortable: true,
        //     visible: false,
        //     thStyle: { width: '150px' },
        //   },
        //   { key: 'actions', label: 'Acciones', visible: true, thStyle: { width: '280px' } },
        // ],
        fields: [],
        fields3: [
          { key: 'created_at', label: 'Fecha de Modificacion', sortable: false, visible: false },
          { key: 'type', label: 'Cambio', sortable: false, visible: false },
          { key: 'date_before', label: 'Fecha Antes', sortable: false, visible: false },
          { key: 'date_after', label: 'Fecha Nueva', sortable: false, visible: false },
          { key: 'user.name', label: 'Usuario', sortable: false, visible: false },
          // { key: 'actions', label: 'Acciones', visible: true }
        ],
        dueno_id: null,
        description: '',
        user_id: null,
        usuarios: [],
        duenos: [],
        date_start: new Date(),
        date_end: new Date(),
        estadosNuevos: [
          {
            text: 'Todas',
            value: '-',
          },
          {
            text: 'Abierto',
            value: 'Abierto',
          },
          {
            text: 'Cerrado',
            value: 'Cerrado',
          },
        ],
        estados: [
          {
            text: 'Rechazado',
            value: 'Rechazado',
          },
          {
            text: 'Liberado por Planner',
            value: 'LiberadoPorPlanner',
          },
        ],
        estadosFilter: [
          {
            text: 'Oficializado',
            value: 'Oficializado',
          },
          {
            text: 'Liberado por Planner',
            value: 'LiberadoPorPlanner',
          },
          {
            text: 'Liberado Por Dueño',
            value: 'LiberadoPorDueño',
          },
        ],
        project_id: JSON.parse(localStorage.getItem('project_id')),
        restriction_id: null,
        records: [],
        checkAll: false,
        selectedRecords: {
          arrayId: [],
        },
        itemId: null,
        projectSelect: '',
        totalElementExport: 0,
        proyectos: [],
        documentSelect: [],
        arrayFilters: [],
        currentPage: 1,
        entries: [10, 20, 50, 100],
        showEntrie: 10,
        totalElements: 0,
        currentPage1: 1,
        entries1: [10, 20, 50, 100],
        showEntrie1: 10,
        totalElements1: 0,
        id: 0,
        sort: 'id',
        order: 'desc',
        isAdd: false,
        dates: [],
        userData: JSON.parse(localStorage.getItem('userData')),
        user_role: JSON.parse(localStorage.getItem('userData')).role_user[0].role_restriction,
        manteiners: true,
        navbar: null,
        filterContent: null,
        tableCard: null,
        tableContainer: null,
        selectableTable: null,
        tableHead: null,
        ths: null,
        trs: null,
        allData: [],
        allDataSorted: [],
      }
    },
    components: {
      vSelect,
      filters,
      flatPickr,
      AppCollapse,
      archivos,
      AppCollapseItem,
      AppTimeline,
      ValidationProvider,
      ValidationObserver,
      AppTimelineItem,
      FilterColumns,

    },
    computed: {
      visibleFields() {
        return this.fields.filter(field => field.visible)
      },
    },
    async mounted() {
      const projectId = localStorage.getItem('project_id')
      const userId = JSON.parse(localStorage.getItem('userData')).id

      await this.fetchFilters()

      this.getSelect()
      this.getOwners(this.project_id)
      await this.filter()
      this.getUsersByProject(this.project_id)

      this.navbar = document.querySelector('.navbar')
      this.filterContent = this.$refs.filterContent
      this.tableContainer = this.$el.querySelector('.table-overflow')
      this.tableCard = this.$refs.tableCard
      this.selectableTable = this.$refs.selectableTable.$el
      this.tableHead = this.selectableTable.querySelector('thead')
      this.ths = this.selectableTable.querySelector('thead').querySelectorAll('th')

      this.setupScrollSync()
      new ResizeObserver(this.fixedElements).observe(this.tableCard)
    },
    watch: {
      records(newVal, oldVal) {
        this.$nextTick(() => {
          this.trs = this.selectableTable.querySelector('tbody').querySelectorAll('tr')
          this.fixedElements()
        })
      },
      visibleFields(newVal, oldVal) {
        this.$nextTick(() => {
          this.ths = this.selectableTable.querySelector('thead').querySelectorAll('th')
          this.fixedElements()
        })
      },
    },
    beforeDestroy() {
      window.removeEventListener('scroll', this.handleWindowScroll)
      window.removeEventListener('resize', this.fixedElements)
    },
    created() {
      // Escucha un evento personalizado llamado 'executeGetData'
      this.$bus.on('executeGetDataRestriction', () => {
        // Llama a la función getData pasando las variables
        console.log('ejecutando rest')
        this.filter()
      })
    },
    methods: {
      async fetchFilters() {
        const payload = {
          viewName: 'restricciones',
          store: this.$store,
        }
        await this.$store.dispatch('filters/fetchFilters', payload)
        this.fields = this.$store.state.filters.filters
        console.log('fields', this.fields)
      },
      fixedElements() {
        if (!this.trs[0].classList.contains('b-table-empty-row')) {
          const thsTotalWidth = [...this.ths].reduce((acc, th) => acc + th.offsetWidth, 0)

          if (thsTotalWidth > this.tableCard.offsetWidth) {
            this.ths.forEach((th, index) => {
              th.style.flex = '0 0 ' + th.offsetWidth + 'px'
            })
          } else {
            this.ths.forEach((th, index) => {
              th.style.flex = '1 1 ' + th.offsetWidth + 'px'
            })
          }

          this.trs.forEach((tr, index) => {
            const tds = tr.querySelectorAll('td')

            this.ths.forEach((th, index) => {
              tds[index].style.width = th.offsetWidth + 'px'

              if (thsTotalWidth > this.tableCard.offsetWidth) {
                tds[index].style.flex = '0 0 ' + th.offsetWidth + 'px'
              } else {
                tds[index].style.flex = '1 1 ' + th.offsetWidth + 'px'
              }
            })
          })
        } else {
          this.selectableTable.style.width = this.tableHead.querySelector('tr').offsetWidth + 1 + 'px'
        }

        this.tableHead.style.width = this.tableCard.offsetWidth - 1 + 'px'
        this.selectableTable.style.paddingTop = this.tableHead.offsetHeight + 'px'
      },
      setupScrollSync() {
        this.tableHead.addEventListener('scroll', () => {
          this.tableContainer.scrollLeft = this.tableHead.scrollLeft
          this.tableHead.style.transform = `translateX(${this.tableHead.scrollLeft}px)`
        })

        this.tableContainer.addEventListener('scroll', () => {
          this.tableHead.scrollLeft = this.tableContainer.scrollLeft
          this.tableHead.style.transform = `translateX(${this.tableHead.scrollLeft}px)`
        })

        window.addEventListener('scroll', this.handleWindowScroll)
        window.addEventListener('resize', this.fixedElements)
      },
      handleWindowScroll() {
        this.filterContent.style.top = this.navbar.offsetHeight + 'px'

        if (this.tableCard.offsetTop - this.navbar.offsetHeight - 7 - window.scrollY <= 0) {
          this.tableHead.classList.add('fixed')
          this.tableHead.style.top = this.navbar.offsetHeight + this.filterContent.offsetHeight + 'px'
        } else {
          this.tableHead.classList.remove('fixed')
          this.tableHead.style.top = null
        }
      },
      async bubbleToast() {
        const elements = document.querySelectorAll('.d-flex.align-items-center')

        const elementoDeseado = Array.from(elements).find(
          elemento => elemento.getAttribute('href') === '/provisionales'
        )

        if (elementoDeseado) {
          /* const resp = await RestrictionsService.getCountBubble(); */
          /* console.log('resp BUBBLE', resp) */
          const spanBubble = document.createElement('p')
          spanBubble.classList.add('bubble')
          spanBubble.textContent = '5'

          elementoDeseado.appendChild(spanBubble)
        } else {
          // Si no se encuentra ningún elemento que cumpla con los criterios
          console.log('No se encontró ningún elemento que coincida con los criterios.')
        }
      },
      openModal(itemId) {
        this.$route.params.id = itemId

        this.itemId = itemId
        this.modalOpen = true
      },
      closeModal() {
        this.modalOpen = false
      },
      //LIBERAR Y RECHAZAR
      liberarRestric(item) {
        var status = 'LiberadoPorPlanner'
        const userData = JSON.parse(localStorage.getItem('userData'))
        if (this.user_role == 'user') {
          let project = userData.projects.find(element => (element.id = this.project_id))

          if (project.is_automatic === 'no' || project.is_automatic == '') status = 'LiberadoPorDueño'
        }

        this.$swal({
          title: '¿Seguro que desea liberar esta restricción?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, liberalo',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            const resp = await RestrictionsService.saveChangeStatus(
              {
                status,
                array_id: item.id,
                project_id: this.project_id,
                user_id: this.userData.id,
              },
              this.$store
            )
            if (resp.status) {
              this.currentPage = 1
              this.$swal({
                icon: 'success',
                title: 'Liberado!',
                text: 'La restricción fue liberada con éxito.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getAllData()
            } else {
              this.$swal({
                icon: 'error',
                title: 'Error',
                text: 'Ocurrió un error al liberar la restricción seleccionada.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          }
        })
      },
      rechazarRestric(item) {
        this.$swal({
          title: '¿Seguro que desea rechazar esta restricción?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, rechazalo',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            const resp = await RestrictionsService.saveChangeStatus(
              {
                status: 'Rechazado',
                array_id: item.id,
                project_id: this.project_id,
                user_id: this.userData.id,
              },
              this.$store
            )
            if (resp.status) {
              this.currentPage = 1
              this.$swal({
                icon: 'success',
                title: 'Rechazado!',
                text: 'La restricción fue rechazada con éxito.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
              this.getAllData()
            } else {
              this.$swal({
                icon: 'error',
                title: 'Error',
                text: 'Ocurrió un error al rechazar la restricción seleccionada.',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }
          }
        })
      },
      //VALIDAR FECHA
      validDate(fecha) {
        if (fecha != null) {
          const year = new Date(fecha).getFullYear()
          if (year <= 1970) {
            return ''
          }
          return moment(fecha, 'yyyy-MM-DD HH:mm').utc(fecha).format('yyyy-MM-DD')
        }
        return ''
      },
      //CERRAR MODAL COMENTARIO
      closeComment() {
        this.comment = ''
        this.addComent = false
      },
      selectProject(id) {
        this.dueno_id = null
        this.project_id = id
        this.getOwners(this.project_id)
        //this.filter()
      },
      filter() {
        this.arrayFilters = []
        if (this.project_id != null && this.project_id != '') {
          this.arrayFilters.push({ keyContains: 'project_id', key: 'equals', value: this.project_id })
        }
        if (this.statusFilter != null && this.statusFilter != '') {
          this.arrayFilters.push({ keyContains: 'status', key: 'equals', value: this.statusFilter })
        }
        if (this.status_similar != null && this.status_similar != '' && this.status_similar != '-') {
          this.arrayFilters.push({ keyContains: 'status_similar', key: 'equals', value: this.status_similar })
        }
        if (this.dueno_id != null && this.dueno_id != '') {
          this.arrayFilters.push({ keyContains: 'owner_id', key: 'equals', value: this.dueno_id })
        }
        if (this.user_id != null && this.user_id != '') {
          this.arrayFilters.push({ keyContains: 'user_id', key: 'equals', value: this.user_id })
        }
        this.getAllData()
      },
      selectAll(val) {
        if (val) {
          this.$refs.selectableTable.selectAllRows()
        } else {
          this.$refs.selectableTable.clearSelected()
        }
        //
      },
      onRowSelectedOrder(items) {
        this.selectedRecords.arrayId = items
      },
      showDates(item) {
        this.restriction_id = item.id
        this.$refs['modal-dates'].show()
        this.getDates(item.id)
      },
      async getDates(id) {
        const resp = await RestrictionsService.getDates(id, this.$store)

        if (resp.status) {
          this.dates = resp.data
          this.totalElements1 = resp.data.length
        }
      },
      showTimeLine(item) {
        this.restriction_id = item.id
        this.$refs['modal-comment'].show()
        this.getTimeLine(item.id)
      },
      async getTimeLine(id) {
        const resp = await RestrictionsService.getComments(id, this.$store)

        if (resp.status) {
          this.timeLine = resp.data
        }
      },
      async addComment() {
        this.$refs.addComentarios.validate().then(async success => {
          if (success) {
            this.isDisabled = true
            let datos = {}
            const userData = JSON.parse(localStorage.getItem('userData'))
            datos.restriction_id = this.restriction_id
            datos.description = this.comment
            datos.user_id = userData.id
            const respComment = await RestrictionsService.saveComent(datos, this.$store)
            if (respComment.status) {
              this.$swal({
                title: 'Registrado',
                text: 'El comentario ha sido registrado.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
              this.addComent = false
              this.getAllData()
              this.$refs['modal-comment'].hide()
            } else {
              this.$swal({
                title: 'Error!',
                text: ' Hubo un error al registrar el comentario',
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            }
            this.isDisabled = false
          }
        })
      },
      async changeStatus() {
        let filterArrays = []
        for (let index = 0; index < this.selectedRecords.arrayId.length; index++) {
          const element = this.selectedRecords.arrayId[index]
          filterArrays.push(element.id)
        }
        this.$swal({
          title: '¿Seguro que desea cambiar a esta(s) restriccion(es)?',
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Si, cambialo',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(async result => {
          if (result.value) {
            if (filterArrays.length > 0) {
              const resp = await RestrictionsService.saveChangeStatus(
                {
                  status: this.status,
                  array_id: filterArrays,
                  project_id: this.project_id,
                  user_id: this.userData.id,
                },
                this.$store
              )
              if (resp.status) {
                this.currentPage = 1
                this.$swal({
                  icon: 'success',
                  title: 'Cambiado!',
                  text: 'Los estados fueron cambiados.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
                this.getAllData()
              } else {
                this.$swal({
                  icon: 'error',
                  title: 'Error',
                  text: 'Ocurrió un error al cambiar el estado de la restricción seleccionada.',
                  customClass: {
                    confirmButton: 'btn btn-success',
                  },
                })
              }
            }
          }
        })
      },
      showFilters() {
        this.isAdd = true
      },
      importData() {
        this.$router.push('/restricciones/importar-datos')
      },
      async exportExcel(id) {
        if (id != null) {
          this.showLoading = true
          // const resp = await RestrictionsService.exportExcel(id,this.$store)
          // console.log('resp',resp)
          var fileLink = document.createElement('a')
          fileLink.href = this.apiurl + '/restriction/download/' + id
          fileLink.setAttribute('target', '_blank')
          document.body.appendChild(fileLink)
          fileLink.click()

          this.showLoading = false
        } else {
          this.$swal({
            icon: 'warning',
            title: 'Proyecto',
            text: 'Seleccione un proyecto para exportar los datos.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      },
      // async generateReport() {
      //   this.showLoading = true
      //   this.showPDF = true
      //   await new Promise((r) => setTimeout(r, 300))
      //   var element = document.getElementById('element-to-print')
      //   var opt = {
      //     margin: 0.4,
      //     filename: 'Restricciones.pdf',
      //     image: { type: 'jpeg', quality: 0.98 },
      //     html2canvas: { scale: 3 },
      //     jsPDF: { unit: 'in', format: 'letter', orientation: 'landscape' }
      //   }
      //   // element.style.display = 'none';
      //   // New Promise-based usage:
      //   await html2pdf().from(element).set(opt).save()
      //   this.showPDF = false
      //   this.showLoading = false
      // },

      cambioPagina(e) {
        this.currentPage = e
        this.records = this.allDataSorted[e - 1]
        /* this.getData() */
      },
      changeSizePage() {
        this.getAllData()
      },
      sortChanged(data) {
        this.sort = data.sortBy
        this.currentPage = 1
        this.getSortedData(data.sortBy, data.sortDesc ? 'desc' : 'asc')
        this.records = this.allDataSorted[0]
        /* this.sort = data.sortBy
      if (data.sortDesc) {
        this.order = 'desc'
      } else this.order = 'asc' */
      },
      cambioPagina1(e) {
        this.currentPage1 = e
        this.getData()
      },
      changeSizePage1() {
        // this.getData()
      },
      addRestric(id) {
        if (id != null) {
          this.$router.push('/restricciones/registro/' + id)
        } else {
          this.$swal({
            icon: 'warning',
            title: 'Proyecto',
            text: 'Seleccione un proyecto para empezar.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      },
      async getSelect() {
        const user = JSON.parse(localStorage.getItem('userData'))
        const url2 = `?limit=100`
        const respProyectos = await ProjectsService.getProyectos(url2, this.$store)

        if (respProyectos.status) {
          this.proyectos = respProyectos.data.rows
        }
      },
      async getUsersByProject(id) {
        const respUsers = await UserService.getUsersByProject(id, this.$store)

        if (respUsers.status) {
          this.usuarios = respUsers.data.rows
        }
      },
      async getOwners(id) {
        const url =
          `?limit=100&filter=` + JSON.stringify([{ keyContains: 'area.project_id', key: 'equals', value: id }])
        const respOwner = await OwnerService.getOwners(url, this.$store)

        if (respOwner.status) {
          /* if(this.user_role =='user'){
          let owner = respOwner.data.rows.find((element) => element.user_id == this.userData.id);
          if(owner){
            this.dueno_id = owner.id;
          }
        } */
          this.duenos = respOwner.data.rows
          this.filter()
        }
      },
      async getData() {
        this.showLoading = true
        const url =
          `?limit=${this.showEntrie}&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` +
          JSON.stringify(this.arrayFilters)
        console.log(url)
        const resp = await RestrictionsService.getRestricciones(url, this.$store)

        if (resp.status) {
          this.records = resp.data.rows
          console.log('this.records', resp)
          this.totalElements = resp.data.responseFilter.total_rows
        }

        this.showLoading = false
      },
      async getAllData() {
        this.showLoading = true
        const url = `?limit=10000&filter=` + JSON.stringify(this.arrayFilters)
        console.log('URL', url)
        const resp = await RestrictionsService.getRestricciones(url, this.$store)
        console.log('GETTING ALL DATA', resp)

        if (resp.status) {
          this.allData = resp.data.rows
          this.records = this.allData
          this.totalElements = resp.data.responseFilter.total_rows
        }
        this.getSortedData('created_at', 'asc')
        console.log('RECORDS SORTS', this.allDataSorted)
        this.records = this.allDataSorted[0]
        this.showLoading = false
      },
      getAttributeValue(obj, attribute) {
        // Si el atributo contiene un punto, es un atributo anidado
        if (attribute.includes('.')) {
          const parts = attribute.split('.')
          let value = obj

          for (const part of parts) {
            if (value && value.hasOwnProperty(part)) {
              value = value[part]
            } else {
              return null // Manejo de error si no se encuentra el atributo anidado
            }
          }

          return value
        } else {
          return obj[attribute]
        }
      },

      getSortedData(sortBy, sortOrder) {
        let sortedData = [...this.allData]
        if (this.description != null && this.description != '') {
          const searchTerm = this.description.toLowerCase()
          sortedData = sortedData.filter(item => item.description.toLowerCase().includes(searchTerm))
        }
        sortedData.sort((a, b) => {
          const aValue = this.getAttributeValue(a, sortBy)
          const bValue = this.getAttributeValue(b, sortBy)

          if (sortOrder === 'asc') {
            return aValue < bValue ? -1 : aValue > bValue ? 1 : 0
          } else if (sortOrder === 'desc') {
            return aValue > bValue ? -1 : aValue < bValue ? 1 : 0
          }
        })

        this.allDataSorted = []
        for (let i = 0; i < sortedData.length; i += this.showEntrie) {
          this.allDataSorted.push(sortedData.slice(i, i + this.showEntrie))
        }
      },
      async getDatosExport() {
        const url =
          `?limit=100&page=${this.currentPage}&order=${this.order}&sort=${this.sort}&filter=` +
          JSON.stringify(this.arrayFilters)
        const resp = await RestrictionsService.getRestricciones(url, this.$store)
        if (resp.status) {
          this.totalData = resp.data.rows
          this.totalElementExport = resp.data.rows.length
        }
      },
      historial(item) {
        this.$refs['modal-historial'].show()
      },
      edit(item) {
        this.$router.push('/restricciones/editar/' + item.id)
      },
      view(item) {
        this.$router.push('/restricciones/vista/' + item.id)
      },
      async deleteAction(data) {
        this.$swal
          .fire({
            title: '¿Desea eliminar esta restricción?',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Si, eliminalo',
            html: `
              <input type="text" style="margin: 15px 0" id="inputValueRestrictions" class="swal2-input" placeholder="Ingrese la razón">
            `,
            customClass: {
              confirmButton: 'btn btn-primary',
              cancelButton: 'btn btn-outline-danger ml-1',
            },
            buttonsStyling: false,
          })
          .then(result => {
            if (result.value) {
              const inputValue = document.getElementById('inputValueRestrictions').value

              this.deleteData(data.id, inputValue)
            }
          })
      },
      async deleteData(id, inputValue) {
        const resp = await RestrictionsService.deleteWithComment(
          {
            id: id,
            reason_reject: inputValue,
            project_id: this.project_id,
            user_id: this.userData.id,
          },
          this.$store
        )
        if (resp.status) {
          this.currentPage = 1
          this.$swal({
            icon: 'success',
            title: 'Eliminado!',
            text: 'La restricción ha sido eliminado.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
          this.getAllData()
        } else {
          this.$swal({
            icon: 'error',
            title: 'Error',
            text: 'Ocurrió un error al eliminar la restricción seleccionada.',
            customClass: {
              confirmButton: 'btn btn-success',
            },
          })
        }
      },
      clean() {
        this.statusFilter = ''
        var arrayFilter = []
        if (this.user_role == 'admin') {
          this.project_id = null
          console.log('ENTEEER')
          /* const proyects = []
        const estados = []
        for (let index = 0; index < this.estados.length; index++) {
          const element = this.estados[index]
          estados.push(element.value)
        }
        if (this.proyectos.length > 0) {
          for (let index = 0; index < this.proyectos.length; index++) {
            const element = this.proyectos[index]
            proyects.push(element.id)
          }
        }
        if (proyects.length > 0) {
          arrayFilter.push({
            keyContains: 'project_id',
            key: 'in',
            value: JSON.stringify(proyects)
          })
        }
        if (estados.length > 0) {
          arrayFilter.push({
            keyContains: 'status',
            key: 'in',
            value: JSON.stringify(estados)
          })
        } */
        }

        this.description = ''
        this.dueno_id = null
        this.user_id = null
        this.statusFilter = ''
        this.status_similar = ''
        this.filter()

        this.arrayFilters = arrayFilter
      },
    },
  }
</script>

<style lang="scss">
  @import '@core/scss/vue/libs/vue-select.scss';
  @import '@core/scss/vue/libs/vue-flatpicker.scss';

  .pad-export {
    padding: 0.52rem 1rem !important;
  }
  .content_main {
    .content_timeline {
      max-height: 450px;
      overflow-y: scroll;
      scroll-behavior: smooth;
    }
    .content_form {
      display: flex;
      justify-content: center;
    }
  }
  .select-obra .vs__dropdown-menu {
    max-height: 200px;
    overflow-y: scroll;
  }
  .mt-02 {
    margin-top: 0.2rem;
  }
  /* .table-container {
  overflow-y: hidden; 
} */

  .table-container {
    max-width: 1300;
    overflow-x: auto;
  }
  .column-actions {
    width: 225px;
  }
  .date-class {
    width: 100px;
  }
  .date-liberado-class {
    width: 94.02px;
  }
  // .description-class{
  //   width: 444.04px;
  // }
  // .owner-class{
  //   width: 194.04px;
  // }
  .vs__dropdown-toggle {
    overflow: hidden !important;
    flex-flow: nowrap !important;
    text-wrap: nowrap !important;
    height: 40px !important;
  }

  .sticky {
    position: sticky;
    z-index: 3;
  }

  .table-overflow {
    overflow-x: auto;
    width: 100%;

    thead {
      display: flex;
      overflow-x: auto;
      position: absolute;
      top: 0;

      &.fixed {
        position: fixed;
        transform: translateX(0px) !important;
        z-index: 2;
      }

      tr {
        display: flex;
        flex: 1 1 auto;

        th {
          flex: 0 0 auto;
          padding: 0.72rem 2rem !important;
        }
      }
    }

    tbody {
      overflow-x: auto;

      tr {
        display: flex;

        &.b-table-empty-row {
          td {
            flex: 0 0 100%;
          }
        }

        td {
          flex: 0 0 auto;
        }
      }
    }
  }
</style>
